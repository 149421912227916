var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal_create_entry",attrs:{"modal-class":"slide-up","size":"lg","on-hide":_vm.clearEntryToSave,"modal-header-title":_vm.$t('users.create taxi user')},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"form_create_entry",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
var validated = ref.validated;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveEntry)}}},[_c('div',{staticClass:"modal-body"},[_c('AppInput',{attrs:{"name":"name","label":_vm.$t('common.name'),"type":"string","rules":"required"},model:{value:(_vm.entryToSave.name),callback:function ($$v) {_vm.$set(_vm.entryToSave, "name", $$v)},expression:"entryToSave.name"}}),_c('AppInput',{attrs:{"name":"email","label":_vm.$t('common.mail address'),"type":"email","rules":_vm.V_EMAIL_REQUIRED},model:{value:(_vm.entryToSave.email),callback:function ($$v) {_vm.$set(_vm.entryToSave, "email", $$v)},expression:"entryToSave.email"}}),_c('AppInput',{attrs:{"name":"phone","label":_vm.$t('common.phone'),"type":"number-length:10","rules":"required|phone"},model:{value:(_vm.entryToSave.phone),callback:function ($$v) {_vm.$set(_vm.entryToSave, "phone", $$v)},expression:"entryToSave.phone"}}),_c('AppSelect',{attrs:{"options":{placeholder: _vm.$t('taxi.taxi company name')},"name":"taxi_company_id","label":_vm.$t('taxi.taxi company name'),"options-data":_vm.listTaxiCompanies,"rules":"required","value":""},model:{value:(_vm.entryToSave.taxi_company_id),callback:function ($$v) {_vm.$set(_vm.entryToSave, "taxi_company_id", $$v)},expression:"entryToSave.taxi_company_id"}}),_c('AppRadio',{attrs:{"radio-inputs":[
                {label: _vm.$t('common.active'), value: 1},
               {label:_vm.$t('common.deactive'), value: 0}
               ],"name":"TO_plan","label":_vm.$t('common.status'),"rules":"required"},model:{value:(_vm.entryToSave.active),callback:function ($$v) {_vm.$set(_vm.entryToSave, "active", $$v)},expression:"entryToSave.active"}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{class:['btn btn-default btn-cons no-margin inline btn-modal-cancel'],attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('button',{class:['btn btn-cons inline btn-modal-submit', 'btn-complete'],attrs:{"disabled":!valid && validated,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])])])]}}])})]},proxy:true}])}),_c('Modal',{ref:"modal_edit_entry",attrs:{"modal-class":"slide-up","size":"lg","on-hide":_vm.clearEntryToEdit,"modal-header-title":_vm.$t('users.update user')},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"form_update_entry",scopedSlots:_vm._u([{key:"default",fn:function(ref){
               var passes = ref.passes;
               var valid = ref.valid;
               var validated = ref.validated;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.updateEntry)}}},[_c('div',{staticClass:"modal-body"},[_c('AppInput',{attrs:{"name":"name","label":_vm.$t('common.name'),"type":"string","rules":"required"},model:{value:(_vm.entryToEdit.name),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "name", $$v)},expression:"entryToEdit.name"}}),_c('AppInput',{attrs:{"name":"email","label":_vm.$t('common.mail address'),"type":"email","rules":_vm.V_EMAIL_REQUIRED},model:{value:(_vm.entryToEdit.email),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "email", $$v)},expression:"entryToEdit.email"}}),_c('AppInput',{attrs:{"name":"phone","label":_vm.$t('common.phone'),"type":"number-length:10","rules":"required|phone"},model:{value:(_vm.entryToEdit.phone),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "phone", $$v)},expression:"entryToEdit.phone"}}),_c('AppSelect',{attrs:{"options":{placeholder: _vm.$t('taxi.taxi company name')},"name":"taxi_company_id","label":_vm.$t('taxi.taxi company name'),"options-data":_vm.listTaxiCompanies,"rules":"required"},model:{value:(_vm.entryToEdit.taxi_company_id),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "taxi_company_id", $$v)},expression:"entryToEdit.taxi_company_id"}}),_c('AppRadio',{attrs:{"radio-inputs":[
                {label: _vm.$t('common.active'), value: 1},
               {label:_vm.$t('common.deactive'), value: 0}
               ],"name":"TO_plan","label":_vm.$t('common.status'),"rules":"required"},model:{value:(_vm.entryToEdit.active),callback:function ($$v) {_vm.$set(_vm.entryToEdit, "active", $$v)},expression:"entryToEdit.active"}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{class:['btn btn-default btn-cons no-margin inline btn-modal-cancel'],attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('button',{class:['btn btn-cons inline btn-modal-submit', 'btn-complete'],attrs:{"disabled":!valid && validated,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])])])]}}])})]},proxy:true}])}),_c('BasicTable',{ref:"table",attrs:{"table-name":_vm.tableName,"settingColumns":_vm.columns,"actions":_vm.actions,"settingTools":_vm.tools,"setting-apis":_vm.apis}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }